import React from 'react';
import styled from 'styled-components';
import { Player } from '@lottiefiles/react-lottie-player';

import loadingChart from '~/assets/lotties/loadingChartWhite.json'

const ContainerLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #4F3A8B;
`;

const AnimatedLoading = styled.div`
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  height: 140px;
  width: 140px;
`;

export default function AnimatedLoadingFull() {
  return (
    <ContainerLoading>
      <AnimatedLoading>
        <Player
          style={{width: '100%'}}
            autoplay
            loop
            speed={1.5}
            src={loadingChart}
        />
      </AnimatedLoading>
    </ContainerLoading>
  )
}
