import React from "react";

import isPresent from '~/utils/isPresent';

import BaseLayout from '~/components/BaseLayout';
import CrudTable from '~/containers/CrudTable';
import Nav from './Nav';

import { QueryParamsProvider } from '~/contexts/QueryParamsContext';
import { FilterProvider } from '~/contexts/FilterContext';

export default function CrudLayoutList({
  breadcrumbs,
  navProps,
  tableProps,
  children,
  permissionKeyCode,
  routes
}) {
  return (
    <QueryParamsProvider>
      <FilterProvider>
        <BaseLayout breadcrumbs={breadcrumbs}>
          <Nav
            withFilters={ isPresent(tableProps?.filterProps?.columns) }
            permissionKeyCode={permissionKeyCode}
            routes={routes}
            {...navProps}
          />
          <CrudTable
            permissionKeyCode={permissionKeyCode}
            routes={routes}
            {...tableProps}
          />
          {children}
        </BaseLayout>
      </FilterProvider>
    </QueryParamsProvider>
  );
}
