import styled, {css} from 'styled-components';
import Typography from '@material-ui/core/Typography';

const colors = {
  info: '#42a5f5',
  error: '#EF5350',
  default: '#BDBDBD'
}

const dragActive = css`
  border-color: #42a5f5;
`;

const dragReject = css`
  border-color: #EF5350;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  padding: 16px 0px
`;

export const DropContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eaeaea;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;

  ${props => props.isDragActive && dragActive}
  ${props => props.isDragReject && dragReject}
`;

export const Input = styled.input`
  -webkit-appearance: initial;
  background-color: initial;
  cursor: default;
  align-items: baseline;
  color: inherit;
  text-align: start !important;
  padding: initial;
  border: initial;
`;

export const Text = styled.span`
  display: block;
  font-weight: 450;
  font-size: 1rem;
  color: ${props => colors[props.color || 'default']};
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`;

export const Title = styled(Typography)`
  margin-bottom: 3px;
`;
