import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const Container = styled.div`
  display: inline-block;
  padding: 20px;
  align-content: center;
  width: 100%;
  &.fullScreen {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #9292926b;
  }
`;

export const Text = styled(Typography)`
  display: block;
  font-size: 1.1rem;
  text-align: center;
  margin-top: 15px;
`;
