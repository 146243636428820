import React, { useState, useEffect, useCallback } from 'react'
import BaseLayout from '~/components/BaseLayout';
import Spinner from '~/components/Spinner';
import Request from '~/services/request';

import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';

import { userReportsApi } from '~/routes/api'

import ReportCard from './components/ReportCard'

export default function Preview() {
  const [reports, setReports] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetchReports().then()
  }, [])

  const fetchReports = useCallback(
    async () => {
      const resp = await Request.get(userReportsApi.list.build())
      setReports(resp.data.data)
      setIsLoading(false)
    },
    [setReports]
  )

  return (
    <BaseLayout showDefaultBreadcrumb={false} breadcrumbs={[
        { text: 'Relatórios' }
    ]} la={reports}>
      { isLoading && <Spinner /> }
      { !isLoading && <RenderReports reports={reports} /> }
    </BaseLayout>
  )
}


const RenderReports = ({reports}) => {
  if(reports.length){
    return (
      <GridContainer>
        { reports.map( report =>
          <Col key={report.id} sm={6} md={4} lg={3}>
            <ReportCard report={report} />
          </Col>
        ) }
      </GridContainer>
    )

  }

  return <h3>Nenhum relatório encontrado</h3>

}