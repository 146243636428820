import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { createRecord, findRecord, clearState } from '~/store/modules/reports';
import ReportRecord from '~/records/ReportRecord';
import { reportsRoute } from '~/routes'

import CrudNew from '~/containers/CrudNew';

import FormFields from './components/FormFields';

export default function New() {
  const { id } = useParams();
  const [record, setRecord] = useState(new ReportRecord());
  const dispatch = useDispatch();
  const { isLoading, current, errors } = useSelector(state => state.reports);

  useEffect(() => {
    if(id){
      dispatch( findRecord(id) );
    }
    return () => {
      dispatch( clearState() )
    };
  }, [])

  useEffect(() => {
    if(current && id){
      setRecord( record.updateAttributes(current) )
    }
  }, [current])

  const onSubmit = useCallback( () => {
    const data = record.toParams();
    dispatch( createRecord(data) );
  }, [record]);

  const handleChange = useCallback(attrs => {
    setRecord(record.updateAttributes(attrs));
  }, [record, setRecord]);

  const clearAfterSaved = useCallback(() => {
    setRecord( new ReportRecord() )
  }, [setRecord]);

  return (
    <CrudNew
      clearAfterSaved={clearAfterSaved}
      onSubmit={onSubmit}
      errors={{ errors }}
      breadcrumbs={[
        { path: reportsRoute.list.path, text: 'Relatórios' },
        { text: 'Cadastrando Relatório' },
      ]}
      footerActionsProps={{isLoading}}
    >
      <FormFields
        record={record}
        onChange={handleChange}
      />
    </CrudNew>
  );
}