import React, { useState, createContext, useEffect, useCallback } from 'react';


import Request from '~/services/request';
import { powerbiApi } from '~/routes/api';

import SpinnerLottie from '~/components/SpinnerLottie'

const PowerBiTokenContext = createContext({
  token: null
});

let scheduleResetToken = null

export function PowerBiTokenProvider( {children, report} ) {
  const [token, setToken] = useState(null)
  const [expireAt, setExpireAt] = useState()
  const [tokenIsValid, setTokenIsValid] = useState(false)

  const fetchToken = useCallback(
    async () => {
      try {
        // const response = await Request.get( powerbiApi.getToken.build({reportID: report?.id}) );
        const response = await Request.get( powerbiApi.getPublicToken.build({reportID: report?.id}) );
        setToken(response.data.token)
        setExpireAt(response.data.expire_at)

        if( !response.data.expire_at ){
          alert("Parece que não foi possível autenticar com o relatório. \n Contate o administrador do sistema.")
        }

      } catch(err) {
        console.error(err)
      }
    },
    [setToken, setExpireAt, report?.id]
  )


  useEffect( () => {
    handleTokenValidation()
    return () => {
      clearTimeout(scheduleResetToken);
    }
  }, [])

  useEffect( () => {
    if( checkTokenIsValid() ){
      setTokenIsValid(true)
      timeoutResetToken()
    }
  }, [tokenIsValid, token, expireAt, checkTokenIsValid, updatePowerBiToken])

  const updatePowerBiToken = useCallback( () => {
    fetchToken().then()
  }, [report?.id])

  const checkTokenIsValid  = useCallback(
    () => {
      return (
        expireAt &&
        parseInt(expireAt, 10) > ( new Date().getTime() / 1000 + 60) &&
        (`${token}`).length > 10
      )
    },
    [expireAt, token]
  )

  const timeoutResetToken  = useCallback(
    () => {
      clearTimeout(scheduleResetToken);

      const resetBeforeMin = 2; // Minutos
      const now = new Date();
      const milliseconds = (parseFloat(expireAt,10) * 1000) - now.getTime() - (resetBeforeMin * 60 * 1000);

      scheduleResetToken = setTimeout(updatePowerBiToken, milliseconds);
    },
    [expireAt, updatePowerBiToken]
  )

  const handleTokenValidation = useCallback(
    () => {
      if ( checkTokenIsValid() ){

        timeoutResetToken()

        if (!tokenIsValid){
          setTokenIsValid(true)
        }
      }else{
        if (tokenIsValid) {
          setTokenIsValid(false)
        }
        updatePowerBiToken()
      }
    },
    [tokenIsValid, setTokenIsValid, updatePowerBiToken, checkTokenIsValid, timeoutResetToken]
  )

  if ( tokenIsValid ){
    return(
      <PowerBiTokenContext.Provider value={{ token }}>
        <div>
          { children }
        </div>
      </PowerBiTokenContext.Provider>
    )
  }else{
    return(
     <SpinnerLottie loadingText="Autenticando..." />
    )
  }
}


export default PowerBiTokenContext;
