import Route from '~/services/route';

export const customRecordsApi = {
  show:    new Route('/api/v1/custom_records/:sys_key/:id'),
  update:  new Route('/api/v1/custom_records/:sys_key/:id'),
  destroy: new Route('/api/v1/custom_records/:sys_key/:id'),
  create:  new Route('/api/v1/custom_records/:sys_key'),
  list:    new Route('/api/v1/custom_records/:sys_key'),
}

export const fieldsApi = {
  show:    new Route('/api/v1/fields/:id'),
  update:  new Route('/api/v1/fields/:id'),
  destroy: new Route('/api/v1/fields/:id'),
  create:  new Route('/api/v1/fields'),
  list:    new Route('/api/v1/fields'),
}

export const metadataApi = {
  show:    new Route('/api/v1/metadata/:id'),
  update:  new Route('/api/v1/metadata/:id'),
  destroy: new Route('/api/v1/metadata/:id'),
  create:  new Route('/api/v1/metadata'),
  list:    new Route('/api/v1/metadata'),
}

export const peopleApi = {
  show:    new Route('/api/v1/people/:id'),
  update:  new Route('/api/v1/people/:id'),
  destroy: new Route('/api/v1/people/:id'),
  create:  new Route('/api/v1/people'),
  list:    new Route('/api/v1/people'),
}

export const rolesApi = {
  show:    new Route('/api/v1/roles/:id'),
  update:  new Route('/api/v1/roles/:id'),
  destroy: new Route('/api/v1/roles/:id'),
  create:  new Route('/api/v1/roles'),
  list:    new Route('/api/v1/roles'),
}

export const permissionsApi = {
  show:    new Route('/api/v1/permissions/:id'),
  update:  new Route('/api/v1/permissions/:id'),
  destroy: new Route('/api/v1/permissions/:id'),
  create:  new Route('/api/v1/permissions'),
  list:    new Route('/api/v1/permissions'),
}

export const usersApi = {
  show:    new Route('/api/v1/admin/users/:id'),
  update:  new Route('/api/v1/admin/users/:id'),
  destroy: new Route('/api/v1/admin/users/:id'),
  create:  new Route('/api/v1/admin/users'),
  list:    new Route('/api/v1/admin/users'),
}

export const authApi = {
  getToken: new Route('/oauth/token'),
  usersPassword: new Route('/api/v1/users/password')
}

export const fileUploadsApi = {
  list:   new Route('/api/v1/file_uploads'),
  update: new Route('/api/v1/file_uploads/:uuid'),
  upload: new Route('/api/v1/file_uploads'),
  delete: new Route('/api/v1/file_uploads/:uuid'),
}

export const utilsApi = {
  searchCep: new Route('/api/v1/utils/search_cep/:cep')
}

export const citiesApi = {
  searchCities: new Route('/api/v1/cities'),
  show: new Route('/api/v1/cities/:id'),
}

export const statesApi = {
  search: new Route('/api/v1/states'),
  show: new Route('/api/v1/states/:id'),
}

export const reportsApi = {
  show:    new Route('/api/v1/admin/reports/:id'),
  update:  new Route('/api/v1/admin/reports/:id'),
  destroy: new Route('/api/v1/admin/reports/:id'),
  create:  new Route('/api/v1/admin/reports'),
  list:    new Route('/api/v1/admin/reports'),
}

export const userReportsApi = {
  show:    new Route('/api/v1/reports/:id'),
  list:    new Route('/api/v1/reports')
}

export const powerbiApi = {
  getToken: new Route('/api/v1/powerbi/embed_token/:reportID'),
  getPublicToken: new Route('/api/v1/powerbi/public_token/:reportID')
}