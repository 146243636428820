import React from 'react'
import { Switch } from 'react-router-dom'

import { reportsRoute } from '~/routes'
import RouteWrapper from '~/routes/RouteWrapper'
import Preview  from './Preview'
import List  from './List'

export default function ReportsRoutesComponent() {
  return (
    <Switch>
      <RouteWrapper
        exact
        isPrivate
        path={reportsRoute.preview.path}
        component={Preview}
      />
      <RouteWrapper
        exact
        isPrivate
        path={reportsRoute.previewList.path}
        component={List}
      />
    </Switch>
  )
}
