import Route from '~/services/route';

export const peopleRoute = {
  list:  new Route('/sys/empresas'),
  show:  new Route('/sys/empresas/:id'),
  clone: new Route('/sys/empresas/new/:id'),
  new:   new Route('/sys/empresas/new'),
  edit:  new Route('/sys/empresas/:id/edit'),
}

export const metadataRoute = {
  list:  new Route('/sys/metadata'),
  show:  new Route('/sys/metadata/:id'),
  clone: new Route('/sys/metadata/new/:id'),
  new:   new Route('/sys/metadata/new'),
  edit:  new Route('/sys/metadata/:id/edit'),
}

export const fieldsRoute = {
  list:  new Route('/sys/fields'),
  show:  new Route('/sys/fields/:id'),
  clone: new Route('/sys/fields/new/:id'),
  new:   new Route('/sys/fields/new'),
  edit:  new Route('/sys/fields/:id/edit'),
}

export const customRecordsRoute = {
  list:  new Route('/sys/r/:sys_key'),
  show:  new Route('/sys/r/:sys_key/:id'),
  clone: new Route('/sys/r/:sys_key/new/:id'),
  new:   new Route('/sys/r/:sys_key/new'),
  edit:  new Route('/sys/r/:sys_key/:id/edit'),
}

export const rolesRoute = {
  list:  new Route('/sys/roles'),
  show:  new Route('/sys/roles/:id'),
  clone: new Route('/sys/roles/new/:id'),
  new:   new Route('/sys/roles/new'),
  edit:  new Route('/sys/roles/:id/edit'),
}

export const usersRoute = {
  list:  new Route('/sys/users'),
  show:  new Route('/sys/users/:id'),
  clone: new Route('/sys/users/new/:id'),
  new:   new Route('/sys/users/new'),
  edit:  new Route('/sys/users/:id/edit'),
}

export const dashboardRoute = {
  self: new Route('/sys/dashboard')
}

export const httpStatusRoute = {
  notFound: new Route('/sys/404')
}
export const authRoute = {
  signIn: new Route('/sys/signin'),
  forgotPassword: new Route('/sys/forgot-password'),
  resetPassword: new Route('/sys/reset-password')
}

export const reportsRoute = {
  list:    new Route('/sys/relatorios'),
  show:    new Route('/sys/relatorios/:id'),
  clone:   new Route('/sys/relatorios/new/:id'),
  new:     new Route('/sys/relatorios/new'),
  edit:    new Route('/sys/relatorios/:id/edit'),

  previewList: new Route('/sys/preview_reports'),
  preview:     new Route('/sys/preview_reports/:id'),
}
