/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import round from '~/utils/Number/round';

import InputRegex from '../InputRegex';

const getMask = (precision, config) => {
  return createNumberMask({
    thousandsSeparatorSymbol:  '.',
    decimalSymbol:             precision > 0 ? ',' : '',
    decimalLimit:              precision,
    includeThousandsSeparator: true,
    allowDecimal:              true,
    requireDecimal:            false,
    allowNegative:             true,
    allowLeadingZeroes:        true,
    prefix:                    '',
    suffix:                    '',
    ...config
  });
};

function inputValueToFloat(event){
  return parseFloat( event.target.value.replace(/\./g, '').replace(/,/g, '.') );
}

class InputNumber extends React.Component {

  static propTypes = {
    value:     PropTypes.oneOfType([
      PropTypes.oneOf([null]),
      PropTypes.string,
      PropTypes.number
    ]),
    precision: PropTypes.number.isRequired,
    config:    PropTypes.object,
    valueType: PropTypes.oneOf(['numeric', 'string']),
  };

  static getDerivedStateFromProps(nextProps) {
    return {mask: getMask(nextProps.precision, nextProps.config) };
  }

  static defaultProps = {
    precision: 2,
    config: {},
    valueType: 'numeric',
  };

  state = {
    mask: '',
  };

  handleChange = (event) => {
    const { onChange, precision, 'state-name': stateName, valueType } = this.props;

    let { value } = event.target;
    if( value !== '' && valueType === 'numeric' ){
      value = round( inputValueToFloat(event), precision);
    }

    onChange({[stateName]: value}, event);
  };

  onKeyDown = (event) =>{
    const keyCode = Number(event.keyCode);
    const config = this.props.config;
    const allowNegative = (config || {}).allowNegative;

    if(allowNegative !== false && (keyCode === 109 || keyCode === 189) ){
      event.preventDefault();
      const { onChange, precision, 'state-name': stateName, valueType } = this.props;
      const number = round( inputValueToFloat(event), precision) * -1;

      onChange({[stateName]: valueType === 'numeric' ? number : String(number)}, event);
    }
  };

  inputValueFormat = () => {
    const { value, precision } = this.props;
    if(typeof value === 'undefined'){
      return value;
    }else if(value === null || value === ''){
      return '';
    }
    let formattedValue = value.toString().replace(/\./g, ',');
    if(precision && precision > 0 && !formattedValue.includes(',') ){
      formattedValue = `${formattedValue},0`
    }
    return formattedValue
  };

  render() {
    // eslint-disable-next-line
    const { precision, onChange, config, value, inputProps, valueType, ...otherProps } = this.props;
    return (
      <InputRegex
        {...otherProps}
        value={ this.inputValueFormat() }
        onChange={this.handleChange}
        onKeyDown={this.onKeyDown}
        mask={this.state.mask}
        inputProps={{
          ...inputProps,
          inputMode: 'numeric'
        }}
      />
    );
  }
}

export default InputNumber;
