import React from 'react';
import { Switch } from 'react-router-dom';

import { reportsRoute } from '~/routes'
import RouteWrapper from '~/routes/RouteWrapper'
import List from './List'
import Show from './Show'
import Edit from './Edit'
import New  from './New'

export default function ReportsRoutesComponent() {
  return (
    <Switch>
      <RouteWrapper
        exact
        isPrivate
        path={reportsRoute.new.path}
        component={New}
        permission="add"
      />
      <RouteWrapper
        exact
        isPrivate
        path={reportsRoute.clone.path}
        component={New}
        permission="add"
      />
      <RouteWrapper
        exact
        isPrivate
        path={reportsRoute.show.path}
        component={Show}
        permission="see"
      />
      <RouteWrapper
        exact
        isPrivate
        path={reportsRoute.edit.path}
        component={Edit}
        permission="edit"
      />

      <RouteWrapper
        isPrivate
        redirectIfNotExact
        path={reportsRoute.list.path}
        component={List}
        permission="see"
      />
    </Switch>
  );
}
