import {
  mapTableRowColumns,
  mapDefaultAttributes,
  mapFilterColumns,
  mapAttributeNames,
  mapHiddenRownColumns,
  mapTableCellColumns
} from './utils';

export default class SchemaBuilder {
  constructor(columns) {
    this.columns = columns;
  }

  // Utilizado para definir os filtros da lista
  get filterColumns () {
    return this._filterColumns || (this._filterColumns = mapFilterColumns( this.columns ))
  }

  // Utilizado para definir quais colunas serão apresentadas na tabela da lista
  get tableCellColumns () {
    return this._tableCellColumns || ( this._tableCellColumns = [
      ...mapTableCellColumns( this.columns ),
      {name: 'actions', title: 'Ações'}
    ] )
  }

  // Utilizado para definir quais colunas das linhas serão apresentadas na tabela da lista
  get tableRowColumns () {
    return this._tableRowColumns || (this._tableRowColumns = [
      ...mapTableRowColumns( this.columns ),
      { columnName: 'actions', width: 160, align: 'center' }
    ])
  }

  // Utilizado para definir quais colunas das linhas serão apresentadas na tabela da lista
  get defaultHiddenRowColumns () {
    return this._defaultHiddenRowColumns || (
      this._defaultHiddenRowColumns = mapHiddenRownColumns(this.columns)
    )
  }

  // Utilizado para definir quais serão os atributos de um Record
  get defaultAttributes () {
    return this._defaultAttributes || ( this._defaultAttributes = mapDefaultAttributes( this.columns ) )
  }

  // Utilizado para definir quais serão os labels dos atributos de um Record
  get attributeNames () {
    return  this._attributeNames || ( this._attributeNames = mapAttributeNames( this.columns ) )
  }
}