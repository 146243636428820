import React, {useMemo, forwardRef} from 'react';
import { Link } from 'react-router-dom';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronDown from '@material-ui/icons/KeyboardArrowDown';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import theme from '~/assets/styles/theme'
import { ListItem } from './styles';

export default function ListItemLink({
  icon: ItemIcon,
  name,
  selected,
  isCollapsible,
  to,
  isCollapsedItem,
  collapseIsOpen,
  onClick
}) {
  const renderLink = useMemo(
    () =>
      forwardRef( (linkProps, ref) => (
        <Link ref={ref} to={to} {...linkProps} />
      )),
    [to],
  );

  function itemClick() {
    onClick && onClick(name);
  }

  const colapseProps = isCollapsible ? {} : { component: renderLink };

  return (
    <li>
      <ListItem
        button
        selected={selected}
        onClick={isCollapsible ? itemClick : undefined}
        isCollapsedItem={isCollapsedItem}
        {...colapseProps}
      >
        <ListItemIcon>
          <ItemIcon htmlColor={theme.palette.menu.contrastText} style={{color: theme.palette.menu.contrastText}}/>
        </ListItemIcon>
        <ListItemText style={{color: theme.palette.menu.contrastText}} primary={name} />

        {isCollapsible && (
          collapseIsOpen
            ? <ChevronDown htmlColor={theme.palette.menu.contrastText} />
            : <ChevronRight htmlColor={theme.palette.menu.contrastText} />
        )}
      </ListItem>
    </li>
  );
}
