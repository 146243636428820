import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { findRecord, destroyRecord, clearState } from '~/store/modules/people';
import { peopleRoute } from '~/routes'

import LayoutShow from '~/components/LayoutShow';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputShow from '~/components/Input/InputShow';
import AttachmentsPreview from '~/components/AttachmentsPreview';

function Show({ person, isLoading }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const onDelete = useCallback(
    () => {
      dispatch( destroyRecord(id) )
    },
    [id]
  )

  useEffect(() => {
    dispatch(findRecord(id, {include: 'attachments'}));

    return () => dispatch(clearState());
  }, []);

  return (
    <LayoutShow
      breadcrumbs={[
        { path: peopleRoute.list.path, text: 'Empresas' },
        { text: 'Exibir Empresa' },
      ]}
      loading={isLoading}
      crudActionsProps={{
        onDelete,
        routeNewRegister:   peopleRoute.new.build(),
        routeCloneRegister: peopleRoute.clone.build({ id }),
        routeEditRegister:  peopleRoute.edit.build({ id }),
        permissionKeyCode:  'Person'
      }}
    >
      <Card>
        <CardContent>
          <InputShow
            label="Nome"
            value={person?.name}
          />
          <InputShow
            label="Razão social"
            value={person?.legal_name}
          />
          <InputShow
            label="CPF/CNPJ"
            type="cpf_cnpj"
            value={person?.cpf_cnpj}
          />
          <GridContainer>
            <Col md={6}>
              <InputShow
                label="Email"
                value={person?.email}
              />
            </Col>
            <Col md={6}>
              <InputShow
                label="Data de nascimento"
                type="date"
                value={person?.birth_date}
              />
            </Col>
          </GridContainer>
          <GridContainer>
            <Col md={6}>
              <InputShow
                label="Celular"
                type="phone_cellphone"
                value={person?.cell_phone}
              />
            </Col>
            <Col md={6}>
              <InputShow
                label="Capital social"
                type="float"
                value={person?.share_capital}
                floatPrecision={2}
              />
            </Col>
          </GridContainer>
          <InputShow
            isHtml
            label="Observacão"
            value={person?.observation}
          />

          <AttachmentsPreview
            title="Anexos"
            attachments={person?.attachments}
          />
        </CardContent>
      </Card>
    </LayoutShow>
  );
}

const mapStateToProps = state => ({
  isLoading: state.people.isLoading,
  person: state.people.current,
});

export default connect(mapStateToProps)(Show);