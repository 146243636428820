import RecordBase from './Base'
import reportSchema from './schema/reportSchema'
import PersonRecord from '~/records/PersonRecord'

export default class ReportRecord extends RecordBase {
  static defaultAttributes = reportSchema.defaultAttributes;

  static _attributeNames = null;

  static get attributeNames() {
    if(!this._attributeNames){
      this._attributeNames = reportSchema.attributeNames;
    }
    return this._attributeNames;
  }

  static belongsTo = { person: PersonRecord };

  toParams() {
    const {
      name,
      pbi_type,
      pbi_id,
      pbi_group_id,
      public_url,
      users
    } = this.toObject();

    return {
      data:{
        attributes:{
          name,
          public_url,
          pbi_type,
          pbi_id,
          pbi_group_id,
          person_id: this.get('person')?.get('id') || null,
          user_ids: Array.isArray(users) ? users.map( e => e.id ) : []
        }
      }
    };
  }
}

window.ReportRecord = ReportRecord
