import React from 'react';
import styled from 'styled-components';
import MDListItem from "@material-ui/core/ListItem";
import theme from '~/assets/styles/theme'

// eslint-disable-next-line
export const ListItem = styled(({ isCollapsedItem, ...otherProps }) => <MDListItem {...otherProps} />)`
  padding-left: ${props => props.isCollapsedItem ? '50px' : '25px'};
  &:hover {
    background-color: ${theme.palette.menu.dark};
  }

  &.Mui-selected {
    background-color: ${theme.palette.menu.dark};
  }

  &.MuiListItem-root:hover {
    background-color: ${theme.palette.menu.dark};
  }
`;
