import SchemaBuilder from './SchemaBuilder';

import { cityAsyncFetch, cityAsyncShow } from '~/services/selectFetch/citiesFetch';

const hiddenList = {tableView: false, hidden: true, isFilter:  false}

const columns = {
  id:            { type: 'integer',  width: 80,  default: null, title: 'ID' },
  name:          { type: 'string',   width: 180, default: '',   title: 'Nome' },
  legal_name:    { type: 'string',   width: 200, default: '',   title: 'Razão Social' },
  email:         { type: 'string',   width: 250, default: '',   title: 'Email' },
  cpf_cnpj:      { type: 'string',   width: 200, default: '',   title: 'CPF/CNPJ' },
  phone:         { type: 'string',   width: 170, default: '',   title: 'Telefone' },
  cell_phone:    { type: 'string',   width: 170, default: '',   title: 'Celular' },
  birth_date:    { type: 'date',     width: 150, default: '',   title: 'Data de Nasc.', hidden: true },
  created_at:    { type: 'datetime', width: 200, default: '',   title: 'Criado em', hidden: true},
  share_capital: { type: 'currency', width: 200, default: '',   title: 'Capital social', hidden: true},
  avatar:        { type: 'file',  ...hiddenList, title: 'Foto'},
  observation:   {
    type: 'string',
    width: 240, default: '',
    title: 'Observação',
    wordWrapEnabled: true,
    hidden: true
  },
  city_id: {
    type:     'async',
    title:    'Cidade',
    tableView: false,
    filterOptions: {
      asyncFetch: cityAsyncFetch,
      asyncShow:  cityAsyncShow
    }
  },
  attachments: {
    default:  [],
    type:     'array',
    title:    'Arquivos',
    tableView: false,
    hidden:    true,
    isFilter:  false,
  }

};

const personSchema = new SchemaBuilder( columns );

export default personSchema;