import styled from 'styled-components';

const drawerMenuPadding = '40px';
const drawerMenuClosePadding = '120px';
const mobilePadding = '16px';

export const Section = styled.section`
  flex-grow: 1;
  padding: ${ ({isMobile, padding}) => (
    typeof padding === 'undefined' ?
    `16px ${isMobile ? mobilePadding : drawerMenuPadding} ${drawerMenuPadding} ${drawerMenuPadding}`
    : `${padding}px`
  )};
  transition: padding-left ${ props => props.drawerOpen ? '0ms' : '200ms'} ease-in-out;
  padding-left: ${
    ({isMobile, drawerOpen, padding}) => isMobile ? mobilePadding : (
      drawerOpen
      ? (padding ? `${padding}px`: drawerMenuPadding)
      : (padding ? `calc(${drawerMenuClosePadding} - ${drawerMenuPadding} + ${padding}px)`: drawerMenuClosePadding)
    )
  };
  @media print {
    padding: 0px
  }
`;