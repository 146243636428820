import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { findRecord, destroyRecord, clearState } from '~/store/modules/reports';
import { reportsRoute } from '~/routes'
import reportPbiTypeEnum from '~/enums/reportPbiTypeEnum';
import ReportRecord from '~/records/ReportRecord';
import isPresent from '~/utils/isPresent'

import LayoutShow from '~/components/LayoutShow';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputShow from '~/components/Input/InputShow';
import Chip from '~/components/Chip';

const attrName = (attr) => ReportRecord.hummanAttributeName(attr);

function Show({ report, isLoading }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const onDelete = useCallback(
    () => {
      dispatch( destroyRecord(id) )
    },
    [id]
  )

  useEffect(() => {
    dispatch(findRecord(id, {include: 'person-users'}));

    return () => dispatch(clearState());
  }, []);

  return (
    <LayoutShow
      breadcrumbs={[
        { path: reportsRoute.list.path, text: 'Relatórios' },
        { text: 'Exibindo Relatório' },
      ]}
      loading={isLoading}
      crudActionsProps={{
        onDelete,
        routeNewRegister:   reportsRoute.new.build(),
        routeCloneRegister: reportsRoute.clone.build({ id }),
        routeEditRegister:  reportsRoute.edit.build({ id })
      }}
    >
      <Card>
        <CardContent>
          <GridContainer>
            <Col md={5}>
              <InputShow
                label={attrName('name')}
                type="string"
                value={report?.name}
              />
            </Col>
            <Col md={4}>
              <InputShow
                label={attrName('person')}
                type="string"
                value={report?.person?.name}
              />
            </Col>
            <Col md={3}>
              <InputShow
                label="Tipo"
                type="string"
                value={ report ? reportPbiTypeEnum().objects()[report.pbi_type] : ''}
              />
            </Col>
          </GridContainer>
          <GridContainer>
            <Col md={6}>
              <InputShow
                label={attrName('pbi_id')}
                type="string"
                value={report?.pbi_id}
              />
            </Col>
            <Col md={6}>
              <InputShow
                label={attrName('pbi_group_id')}
                type="string"
                value={report?.pbi_group_id}
              />
            </Col>
          </GridContainer>
          <GridContainer>
            <Col>
              <Typography>Usuários</Typography>
              { report?.users?.filter(isPresent)?.map( u =>
                <Chip key={u.id} label={`${u.name} <${u.email}>`} variant='outlined' color='primary' /> )
              }
            </Col>
          </GridContainer>
        </CardContent>
      </Card>
    </LayoutShow>
  );
}

const mapStateToProps = state => ({
  isLoading: state.reports.isLoading,
  report: state.reports.current,
});

export default connect(mapStateToProps)(Show);