import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { Player } from '@lottiefiles/react-lottie-player';
import dashboards from '~/assets/lotties/dashboards.json'
import { Link as RouteLink } from 'react-router-dom';
import { reportsRoute } from '~/routes'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  }
});

export default function MediaCard({report}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea
        component={RouteLink}
        to={reportsRoute.preview.build({id: report.id}) }
      >
        <CardMedia
          className={classes.media}
        >
          <Player
            style={{width: '100%'}}
              keepLastFrame
              autoplay
              hover
              speed={0.7}
              src={dashboards}
          />
        </CardMedia>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {report.name}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          component={RouteLink}
          to={reportsRoute.preview.build({id: report.id}) }
        >
          Visualizar
        </Button>
      </CardActions>
    </Card>
  );
}
