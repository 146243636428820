import React, { useMemo, useContext } from 'react'
import { makeStyles } from "@material-ui/core/styles";

// import { models } from 'powerbi-client';
// import { PowerBIEmbed } from 'powerbi-client-react'
import PowerBiTokenContext from '~/contexts/PowerBiTokenContext'

const useStyles = makeStyles( () => ({
  reportContainer: {
    height: 'calc(100vh - 80px)'
  },
  iframe: {
    height: 'calc(100vh - 80px)',
    width: '100%',
  },
  hiddeSocial: {
    position: 'fixed',
    bottom: '10px',
    right: '0px',
    zIndex: 999,
    height: '36px',
    width: '200px',
    background: '#EAEAEA'
  }
}));

// const baseURL = 'https://app.powerbi.com'
export default function PowerBiPublicWraper({report}) {
  const classes = useStyles();
  const { token } = useContext(PowerBiTokenContext);

  const embedUrl = useMemo(()=>{
    return `/powerbi/embed_url?token=${token}`
    // const group = `groupId=${report.pbi_group_id}`;
    // if(report.pbi_type === 'report'){
    //   return `${baseURL}/reportEmbed?reportId=${report.pbi_id}&${group}`
    // }
    // return `${baseURL}/dashboardEmbed?dashboardId=${report.pbi_id}&${group}`
  }, [report.id])

  return <div className={ `printable ${classes.reportContainer}` }>
    <div className={classes.hiddeSocial}></div>
    <iframe
      id="pbi-iframe"
      src={embedUrl}
      className={classes.iframe}
      allowFullScreen
      frameBorder="0"
    />
  </div>
}