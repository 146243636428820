const white = {
  light: '#FFFFFF',
  main: '#FFFFFF',
  dark: '#f7f3f3',
  contrastText: '#3949ab',
};

const blue = {
  light: '#72FFFF',
  main: '#00FFFF',
  dark: '#00CBCC',
  contrastText: '#FFF',
};

const green = {
  light: '#81C784',
  main: '#4CAF50',
  dark: '#388E3C',
  contrastText: '#FFF',
};

const orange = {
  light: '#FF9800',
  main: '#FF9800',
  dark: '#C66900',
  contrastText: '#FFF',
};

const red = {
  light: '#FF908A',
  main: '#FE5D5D',
  dark: '#C52533',
  contrastText: '#FFF',
};

const rose = {
  light: '#FF60FF',
  main: '#FA00E1',
  dark: '#C300AE',
  contrastText: '#FFF',
};

const grey = {
  light: '#9E9D9D',
  main: '#706F6F',
  dark: '#454444',
  contrastText: '#FFF',
};

const purple = {
  light: '#7F65BC',
  main: '#4F3A8B',
  dark: '#1F125D',
  contrastText: '#FFF',
};

const black = {
  light: '#434C59',
  main: '#1B2430',
  dark: '#000007',
  contrastText: '#FFF',
};

const indigo = {
  light: '#5c6bc0',
  main: '#3949ab',
  dark: '#1a237e',
  contrastText: '#FFF',
};

export default {
  palette: {
    primary: indigo,
    secondary: black,
    success: green,
    warning: orange,
    error: red,
    menu: white,
    appBar: white,
    blue,
    purple,
    green,
    orange,
    red,
    black,
    grey,
    rose,
    white,
    indigo
  },

  overrides: {
    MuiTooltip: {
      tooltip: {
        padding: 10,
        fontSize: '0.8rem',
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#FFF',
      },
      root: {
        height: 2,
      },
    },
    MuiInputBase: {
      inputMultiline: {
        resize: 'vertical'
      }
    }
  },
};
