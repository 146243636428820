import React from 'react';

import RouteWrapper from './RouteWrapper';
import ReportPreview from '~/pages/ReportPreview';
import { dashboardRoute } from '~/routes'

export default function DefaultOriginRouteComponent(){
  return <RouteWrapper
    isPrivate
    redirectIfNotExact
    path={dashboardRoute.self.path}
    component={ReportPreview}
    permission="see"
  />
}