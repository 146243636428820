import React, { useCallback } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Input from '@material-ui/core/Input';

import InputControl from '~/components/Input/InputControl';
import InputCpfCnpj from '~/components/Input/InputCpfCnpj';
import InputDate from '~/components/Input/InputDate';
import InputPhone from '~/components/Input/InputPhone';
import InputNumber from '~/components/Input/InputNumber';
import InputFile from '~/components/Input/InputFile';
import InputTextEditor from '~/components/Input/InputTextEditor';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';

import PersonRecord from '~/records/PersonRecord';

import InputDropzoneFile from '~/containers/InputDropzoneFile';

const attrName = (attr) => PersonRecord.hummanAttributeName(attr);

export default function FormFields({ record, onChange}) {
  const onChangeFiles = useCallback(
    (attachments) => {
      onChange( {attachments} )
    },
    [record, onChange],
  )

  return (
    <Card>
      <CardContent>
        <GridContainer>
          <Col xs={12} sm={6} md={8}>
            <InputControl
              autoFocus
              required
              name="name"
              label={attrName('name')}
              value={record.get('name')}
              type="text"
              inputComponent={Input}
              onChange={onChange}
            />
          </Col>
          <Col xs={12} sm={6} md={4}>
            <InputControl
              name="avatar"
              label={attrName('avatar')}
              value={record.get('avatar')}
              inputComponent={InputFile}
              onChange={onChange}
              InputProps={{
                options: {
                  accept: 'image/jpeg, image/png'
                }
              }}
            />
          </Col>
        </GridContainer>

        <InputControl
          name="legal_name"
          label={attrName('legal_name')}
          value={record.get('legal_name')}
          type="text"
          inputComponent={Input}
          onChange={onChange}
        />
        <InputControl
          name="cpf_cnpj"
          label={attrName('cpf_cnpj')}
          value={record.get('cpf_cnpj')}
          type="number"
          inputComponent={InputCpfCnpj}
          onChange={onChange}
        />

        <GridContainer>
          <Col md={6}>
            <InputControl
              name="email"
              label={attrName('email')}
              value={record.get('email')}
              type="email"
              inputComponent={Input}
              onChange={onChange}
            />
          </Col>
          <Col md={6}>
            <InputControl
              name="birth_date"
              label={attrName('birth_date')}
              value={record.get('birth_date')}
              type="date"
              inputComponent={InputDate}
              onChange={onChange}
            />
          </Col>
        </GridContainer>

        <GridContainer>
          <Col md={6}>
            <InputControl
              name="cell_phone"
              label={attrName('cell_phone')}
              value={record.get('cell_phone')}
              type="number"
              inputComponent={InputPhone}
              onChange={onChange}
            />
          </Col>
          <Col md={6}>
            <InputControl
              name="share_capital"
              label={attrName('share_capital')}
              value={record.get('share_capital')}
              type="number"
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
        </GridContainer>

        <InputControl
          name="observation"
          label={attrName('observation')}
          value={record.get('observation')}
          type="text"
          inputComponent={InputTextEditor}
          onChange={onChange}
        />

        <InputDropzoneFile
          title="Anexos"
          key={record.get('id') || 0}
          // onDelete={onDeleteFile}
          onChange={onChangeFiles}
          persistedFiles={ record.get('attachments') }
        />
      </CardContent>
    </Card>
  );
}