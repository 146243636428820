import React, { useContext, useEffect } from "react";

import DrawerMenuContext from '~/contexts/DrawerMenuContext';
import { useBreadcrumbs } from '~/hooks/useBreadcrumbs';
import { usePermissions } from '~/hooks/usePermissions';
import { dashboardRoute } from '~/routes';
import isPresent from '~/utils/isPresent';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Toolbar from '../Toolbar';
import Breadcrumbs from '../Breadcrumbs';

import { Section } from './styles';

export default function BaseLayout({
  breadcrumbs,
  showDefaultBreadcrumb = true,
  padding,
  children
}) {
  const { drawerMenuStorageState } = useContext(DrawerMenuContext);
  const { can } = usePermissions();
  const { setBreadcrumbs } = useBreadcrumbs();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const hasPermissionToAccessDashboard = can('Dashboard', 'see');
    const newBreadcrumbs = breadcrumbs || [];
    let defaultBreadcrumb = null;

    if (showDefaultBreadcrumb && hasPermissionToAccessDashboard) {
      defaultBreadcrumb = { path: dashboardRoute.self.path, text: 'Início' };
    }

    setBreadcrumbs(
      isPresent(defaultBreadcrumb)
        ? [defaultBreadcrumb, ...newBreadcrumbs]
        : newBreadcrumbs
    );
  }, []);

  return (
    <Section className="printable" padding={padding} drawerOpen={drawerMenuStorageState} isMobile={isMobile}>
      <Toolbar />
      <Breadcrumbs />
      {children}
    </Section>
  );
}
