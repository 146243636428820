import { combineReducers } from 'redux';

import crud from './modules/crud';
import notifications from './modules/notifications';
import forgotPassword from './modules/forgotPassword';
import resetPassword from './modules/resetPassword';
import userProfile from './modules/userProfile';
import auth from './modules/auth';
import users from './modules/users';
import fields from './modules/fields';
import people from './modules/people';
import roles from './modules/roles';
import metadata from './modules/metadata';
import customRecords from './modules/customRecords';
import reports from './modules/reports';

export default combineReducers({
  crud,
  notifications,
  auth,
  userProfile,
  people,
  roles,
  metadata,
  forgotPassword,
  resetPassword,
  users,
  fields,
  customRecords,
  reports,
});
