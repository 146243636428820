import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import { Player } from '@lottiefiles/react-lottie-player';

import authWaiting from '~/assets/lotties/authWaiting.json'

import { Container, Text } from './styles';

function SpinnerLottie({
  loading,
  loadingText,
  loadingTextStyle,
  fullScreen
}) {
  if (!loading) return <Fragment />;

  return (
    <Container className={fullScreen ? 'fullScreen' : ''}>
      <Player
        style={{width: '20%'}}
          autoplay
          loop
          speed={1.5}
          src={authWaiting}
      />

      {!!loadingText && (
        <Text style={loadingTextStyle} variant="h5" color="primary">{loadingText}</Text>
      )}
    </Container>
  );
}

SpinnerLottie.defaultProps = {
  loading: true,
  fullScreen: false,
  loadingText: 'Carregando...',
  loadingTextStyle: {}
}

SpinnerLottie.propTypes = {
  loading: PropTypes.bool,
  fullScreen: PropTypes.bool,
  loadingText: PropTypes.string,
  loadingTextStyle: PropTypes.object
}

export default SpinnerLottie;
