import RecordBase from './Base';
import personSchema from './schema/personSchema';
import ArchiveRecord from '~/records/ArchiveRecord';

export default class PersonRecord extends RecordBase {
  static defaultAttributes = personSchema.defaultAttributes;

  static _attributeNames = null;

  static get attributeNames() {
    if(!this._attributeNames){
      this._attributeNames = personSchema.attributeNames;
    }
    return this._attributeNames;
  }

  static hasMany = { attachments: ArchiveRecord };
  static belongsTo = { avatar: ArchiveRecord };

  toParams(extra={}) {
    const {
      name,
      legal_name,
      cpf_cnpj,
      email,
      birth_date,
      phone,
      cell_phone,
      share_capital,
      observation
    } = this.toObject();

    return {
      data:{
        attributes:{
          name,
          legal_name,
          cpf_cnpj,
          email,
          birth_date,
          phone,
          cell_phone,
          share_capital,
          observation,
          avatar_id: this.get('avatar')?.get('id') || null,
          attachments_ids:     ArchiveRecord.getNewFiles( this.get('attachments') ),
          del_attachments_ids: ArchiveRecord.getDeleteFiles( this.get('attachments') ),
          ...extra
        }
      }
    };
  }
}

window.PersonRecord = PersonRecord