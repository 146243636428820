import React, { useState, useEffect, useCallback } from 'react'
import BaseLayout from '~/components/BaseLayout';
// import PowerBIHadleToken from '~/containers/PowerBIHadleToken';
// import PowerBiWraper from '~/containers/PowerBiWraper';
import PowerBiPublicWraper from '~/containers/PowerBiPublicWraper';
import Spinner from '~/components/Spinner';
import Request from '~/services/request';
import { useParams } from 'react-router-dom';

import { PowerBiTokenProvider } from '~/contexts/PowerBiTokenContext'

import { userReportsApi } from '~/routes/api'

export default function Preview() {
  const { id } = useParams();
  const [report, setReport] = useState(null)

  useEffect(() => {
    fetchReport().then()
  }, [id])

  const fetchReport = useCallback(
    async () => {
      const resp = await Request.get(userReportsApi.show.build({id}))
      setReport(resp.data.report)
    },
    [id, setReport]
  )

  if (!report){
    return (
      <Spinner />
    )
  }

  return (
    <BaseLayout padding={4} showDefaultBreadcrumb={false}>
      <PowerBiTokenProvider report={report}>
        { /* <PowerBiWraper report={report} /> */ }
        <PowerBiPublicWraper report={report} />
      </PowerBiTokenProvider>
    </BaseLayout>
  )
}
