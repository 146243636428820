import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Path as PathParser } from 'path-parser';
import history from '../services/history';
import { store } from '../store';
import { reportsRoute, httpStatusRoute } from '~/routes'
import { usePermissions } from '../hooks/usePermissions';
import { authRoute } from '~/routes'

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  redirectIfNotExact = false,
  path,
  permissionKeyCode,
  permission,
  ...rest
}) {
  const { signed } = store.getState().auth;
  const { can } = usePermissions();

  if (signed && isPrivate) {
    const hasPermissionToAccessRoute = can(permissionKeyCode, permission);
    if (!hasPermissionToAccessRoute && !path.startsWith(httpStatusRoute.notFound.path)) {
      const hasPermissionToAccessDashboard = can('Dashboard', 'see');

      if (hasPermissionToAccessDashboard) {
        return <Redirect to={reportsRoute.previewList.path} />;
      } else {
        return <Redirect to={httpStatusRoute.notFound.path} />;
      }
    }
  }

  const loggedOutUserTryingAccessPrivateRoute = !signed && isPrivate;
  if (loggedOutUserTryingAccessPrivateRoute) {
    return <Redirect to={authRoute.signIn.path} />;
  }

  const loggedUserTryingAccessPublicRoute = signed && !isPrivate;
  if (loggedUserTryingAccessPublicRoute) {
    return <Redirect to={reportsRoute.previewList.path} />;
  }

  // É necessaŕio usar o PathParser pois o path pode ter parâmetros extras
  const invalidRoute = (new PathParser(path)).test( history.location.pathname ) === null;
  const loggedUserTryingAccessUnmappedPrivateRoute = signed && isPrivate && invalidRoute;
  if (loggedUserTryingAccessUnmappedPrivateRoute && redirectIfNotExact) {
    return <Redirect to={path} />;
  }

  return <Route { ...rest } path={path} component={Component} />;
}
