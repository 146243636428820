import React from 'react';
import Grid  from '@material-ui/core/Grid';

export default function Col({children, ...props }) {
  return (
    <Grid item {...props} >
      {children}
    </Grid>
  );
}
