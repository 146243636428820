import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Input from '@material-ui/core/Input';

import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputControl from '~/components/Input/InputControl';
import InputSelect from '~/components/Input/InputSelect';
import InputSelectAsync from '~/components/Input/InputSelectAsync';

import ReportRecord from '~/records/ReportRecord';
import reportPbiTypeEnum from '~/enums/reportPbiTypeEnum';
import peopleFetch from '~/services/selectFetch/peopleFetch'

import Request from '~/services/request';

const attrName = (attr) => ReportRecord.hummanAttributeName(attr);

const fetchUsers = async (companyID) => {
  const resp = await Request.get(`/api/v1/admin/loaders/users?company_id=${companyID}`)

  return resp.data.data.map( ({id, name}) => ({id: `${id}`, name}) )
}

export default function FormFields({record, onChange}) {
  const [users, setUsers] = useState([])

  useEffect( () => {
    fetchUsers(record.get('person')?.get('id')).then( usrs => {
      setUsers( usrs )
    })
  }, [record.get('person')])

  return (
    <div>
      <Card>
        <CardContent>
          <GridContainer>
            <Col xs={12} sm={5}>
              <InputControl
                required
                name="name"
                label={attrName('name')}
                value={record.get('name')}
                type="text"
                inputComponent={Input}
                onChange={onChange}
              />
            </Col>
            <Col xs={12} sm={4}>
              <InputControl
                required
                value={record.get('person')}
                name={'person'}
                label={attrName('person')}
                inputComponent={InputSelectAsync}
                onChange={onChange}
                InputProps={{
                  handleFetch: peopleFetch,
                  createEnabled: false,
                }}
              />
            </Col>
            <Col xs={12} sm={3}>
              <InputControl
                required
                value={record.get('pbi_type')}
                name={'pbi_type'}
                label={attrName('pbi_type')}
                inputComponent={InputSelect}
                onChange={onChange}
                InputProps={{
                  options: reportPbiTypeEnum().array,
                  outValueType: 'string'
                }}
              />
            </Col>
          </GridContainer>
          <GridContainer>
            <Col xs={12}>
              <InputControl
                required
                name="public_url"
                label={attrName('public_url')}
                value={record.get('public_url')}
                inputComponent={Input}
                autoComplete="off"
                onChange={onChange}
              />
            </Col>
            <Col xs={12} sm={6}>
              <InputControl
                name="pbi_id"
                label={attrName('pbi_id')}
                value={record.get('pbi_id')}
                inputComponent={Input}
                autoComplete="off"
                onChange={onChange}
              />
            </Col>
            <Col xs={12} sm={6}>
              <InputControl
                name="pbi_group_id"
                label={attrName('pbi_group_id')}
                value={record.get('pbi_group_id')}
                inputComponent={Input}
                autoComplete="off"
                onChange={onChange}
              />
            </Col>
            <Col xs={12}>
              <InputControl
                required
                value={record.get('users')}
                name={'users'}
                label={attrName('users')}
                inputComponent={InputSelect}
                onChange={onChange}
                InputProps={{
                  options: users,
                  createEnabled: false,
                  isMulti: true
                }}
              />
            </Col>
          </GridContainer>
        </CardContent>
      </Card>
    </div>
  );
}