import React, { useCallback } from 'react';

import { reportsRoute } from '~/routes'
import CrudLayoutList from '~/containers/CrudLayoutList';
import reportSchema from '~/records/schema/reportSchema';
import { useDispatch, connect } from 'react-redux';
import { fetchRecords, destroyRecord } from '~/store/modules/reports';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '~/components/IconButton';
import BarChartIcon from '@material-ui/icons/BarChart';

function List({isLoading, rows, total}) {
  const dispatch = useDispatch();

  const handleFetch = useCallback(
    async (params, requestSource) => {
      dispatch(
        fetchRecords(`${params}&include=person`, requestSource)
       )
    },
    [],
  )

  const onDelete = useCallback(
    async (row) => {
      dispatch( destroyRecord(row.id) )
    },
    []
  )

  return (
    <CrudLayoutList
      breadcrumbs={[{ text: 'Relatórios' }]}
      routes={{
        newPath:  ()  =>  reportsRoute.new.build(),
        showPath: (id) => reportsRoute.show.build({id}),
        editPath: (id) => reportsRoute.edit.build({id})
      }}
      tableProps={{
        extraActions: (row) => {
          return (
            <Link to={reportsRoute.preview.build({id: row.id})}>
              <IconButton>
                <Tooltip title="Ver relatório">
                  <BarChartIcon htmlColor="#3949AB" />
                </Tooltip>
              </IconButton>
            </Link>
          )
        },
        isLoading,
        rows,
        total,
        handleFetch,
        onDelete,
        schema: reportSchema,
        actions: ['show', 'edit', 'delete'],
        filterProps: {
          columns: reportSchema.filterColumns
        },
        columnsOverride: [
          {
            name: 'person',
            formatterComponent: (columnName, row) => <span>{row[columnName]?.name}</span>
          }
        ]
      }}
    />
  );
}

const mapStateToProps = state => ({
  isLoading: state.reports.listIsLoading,
  rows: state.reports.data,
  total: state.reports.totalData,
});

export default connect(mapStateToProps)(List);