import React from 'react';

// import Typography from '@material-ui/core/Typography';
// import imgLogo from '~/assets/images/report-image.png'
// import InstagramIcon from '@material-ui/icons/Instagram';
// import LanguageIcon from '@material-ui/icons/Language';
// import MailOutlineIcon from '@material-ui/icons/MailOutline';
// import PhoneIcon from '@material-ui/icons/Phone';
// import RoomIcon from '@material-ui/icons/Room';

// import {
//   Header, Image, Content, Text, Icon
// } from './styles'

// function HeaderPrint() {
//   return (
//     <Header className="only-print">
//       <Image src={imgLogo} />
//       <Content>
//         <Typography variant="subtitle1" component="h1">
//           <strong>TWOWEB AGÊNCIA DIGITAL</strong>
//         </Typography>
//         <Text component="a" href="https://goo.gl/maps/CjEDHehhbmmPghEn7" target="_blank" rel="noopener">
//           <Icon><RoomIcon /></Icon>
//           Rua Eduardo Pedroso da Silva, 195-E Sala 311 - Efapi, Chapecó - SC, 89800-000
//         </Text>
//         <Text component="a" href="tel:+554933161107">
//           <Icon><PhoneIcon /></Icon>
//           (49) 3316-1107
//         </Text>
//         <Text component="a" href="mailto:twoweb@twoweb.com.br">
//           <Icon><MailOutlineIcon /></Icon>
//           twoweb@twoweb.com.br
//         </Text>
//       </Content>
//       <Content style={{textAlign: 'right', marginLeft: 'auto'}}>
//         <Text component="a" href="https://twoweb.digital" target="_blank" rel="noopener">
//           <Icon><LanguageIcon /></Icon>
//           https://twoweb.digital
//         </Text>
//         <Text component="a" href="https://instagram.com/twoweb.digital" target="_blank" rel="noopener">
//           <Icon><InstagramIcon /></Icon>
//           @twoweb.digital
//         </Text>
//       </Content>
//     </Header>
//   );
// }

// export default React.memo(HeaderPrint)

export default () => <h3 className="only-print">Paris Antunes</h3>