import DashboardIcon from '@material-ui/icons/Dashboard';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DescriptionIcon from '@material-ui/icons/Description';
import AdministrationIcon from '@material-ui/icons/AttachMoney';
import PermissionIcon from '@material-ui/icons/Lock';
import UserIcon from '@material-ui/icons/Person';
import StorageIcon from '@material-ui/icons/Storage';
import InputIcon from '@material-ui/icons/Input';
import ApartmentIcon from '@material-ui/icons/Apartment';
import AssessmentIcon from '@material-ui/icons/Assessment';

import {
  peopleRoute,
  metadataRoute,
  fieldsRoute,
  rolesRoute,
  usersRoute,
  reportsRoute
} from '~/routes'

export const drawerMenuAvatar = {
  name: 'Halley Specter',
  roleName: 'Admin',
  url: 'https://66.media.tumblr.com/3e3f627161fb69b617504b320b57d480/tumblr_pojtnodtMz1rfd9dz_640.jpg',
  onClick: () => {},
};

const drawerMenuItems = [
  {
    name: 'Relatórios',
    icon: DashboardIcon,
    to: reportsRoute.previewList.path
  },
  {
    name: 'Administração',
    icon: AdministrationIcon,
    collapse: [
      {
        name: 'Empresas',
        icon: ApartmentIcon,
        keyCode: 'Person',
        to: peopleRoute.list.path
      },
      {
        name: 'Relatórios',
        icon: AssessmentIcon,
        keyCode: 'Reports',
        to: reportsRoute.list.path
      },
      {
        name: 'Permissões',
        icon: PermissionIcon,
        keyCode: 'Permission',
        to: rolesRoute.list.path
      },
      {
        name: 'Usuários',
        icon: UserIcon,
        keyCode: 'User',
        to: usersRoute.list.path
      }
    ]
  }, {
    name: 'Master',
    icon: DragIndicatorIcon,
    collapse: [
      {
        name: 'Metadados',
        icon: StorageIcon,
        keyCode: 'Metadata',
        onlyMaster: true,
        to: metadataRoute.list.path
      },
      {
        name: 'Campos',
        icon: InputIcon,
        keyCode: 'Field',
        onlyMaster: true,
        to: fieldsRoute.list.path
      },{
        onlyMaster: true,
        name: 'Examples',
        icon: DescriptionIcon,
        keyCode: 'Example',
        to: '/sys/components-example'
      }
    ]
}];

export default drawerMenuItems