import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import drawerMenuItems, { drawerMenuAvatar } from '~/routes/drawerMenu';

import DefaultOriginRouteComponent from '~/routes/DefaultOriginRoute'

import { httpStatusRoute } from '~/routes'
import NotFoundComponent from '~/pages/NotFound';

import { peopleRoute } from '~/routes'
import PeopleComponent from '~/pages/People';

import { metadataRoute } from '~/routes'
import MetadataComponent from '~/pages/Metadata';

import { fieldsRoute } from '~/routes'
import FieldsComponent from '~/pages/Fields';

import { customRecordsRoute } from '~/routes'
import CustomRecordsComponent from '~/pages/CustomRecords';

import { usersRoute } from '~/routes'
import UsersComponent from '~/pages/Users';

import { rolesRoute } from '~/routes'
import RolesComponent from '~/pages/Roles';

import { reportsRoute } from '~/routes'
import ReportComponent from '~/pages/Reports'
import { dashboardRoute } from '~/routes'
import ReportPreviewComponent from '~/pages/ReportPreview';

import ExamplesComponent from '~/pages/Examples';

import DrawerMenu from '~/components/DrawerMenu';
import ProfileMenu from '~/components/ProfileMenu';

import { PermissionsProvider } from './hooks/usePermissions';
import { MetadataProvider } from './hooks/useMetadata';
import { ProfileDrawerProvider } from './contexts/ProfileDrawerContext';
import { DrawerMenuProvider } from './contexts/DrawerMenuContext';
import { BreadcrumbsProvider } from './hooks/useBreadcrumbs';

const RedirectToReports = () => <Redirect to={reportsRoute.previewList.path} />

function AppRoutes() {
  return (
    <Switch>
      <Route path={peopleRoute.list.path} component={PeopleComponent} />
      <Route path={customRecordsRoute.list.path} component={CustomRecordsComponent} />
      <Route path={rolesRoute.list.path} component={RolesComponent} />
      <Route path={usersRoute.list.path} component={UsersComponent} />
      <Route path={reportsRoute.list.path} component={ReportComponent} />
      <Route path={reportsRoute.previewList.path} component={ReportPreviewComponent} />
      <Route path={dashboardRoute.self.path} component={RedirectToReports} />

      {/* MASTER */}
      <Route path={metadataRoute.list.path} component={MetadataComponent} />
      <Route path={fieldsRoute.list.path} component={FieldsComponent} />
      <Route path={'/sys/components-example'} component={ExamplesComponent} exact />

      <Route path={httpStatusRoute.notFound.path} component={NotFoundComponent} />
      <Route component={DefaultOriginRouteComponent} />
    </Switch>
  );
}

export default function PrivateRoutes() {
  return(
    <DrawerMenuProvider>
      <BreadcrumbsProvider>
        <PermissionsProvider>
          <MetadataProvider>
            <ProfileDrawerProvider>
              <DrawerMenu avatar={drawerMenuAvatar} items={drawerMenuItems} />
              <ProfileMenu />
              <AppRoutes />
            </ProfileDrawerProvider>
          </MetadataProvider>
        </PermissionsProvider>
      </BreadcrumbsProvider>
    </DrawerMenuProvider>
  );
}