import React, { useMemo , useState, useEffect} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Input from '@material-ui/core/Input';

import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputControl from '~/components/Input/InputControl';
import InputSelect from '~/components/Input/InputSelect';
import InputSelectAsync from '~/components/Input/InputSelectAsync';
import peopleFetch from '~/services/selectFetch/peopleFetch'

import UserRecord from '~/records/UserRecord';
import userAcessTypeEnum from '~/enums/userAcessTypeEnum';
import Request from '~/services/request';

const attrName = (attr) => UserRecord.hummanAttributeName(attr);

const fetchReports = async (companyID) => {
  const resp = await Request.get(`/api/v1/admin/loaders/reports?company_id=${companyID}`)

  return resp.data.data.map( ({id, name}) => ({id: `${id}`, name}) )
}

export default function FormFields({record, onChange}) {
  const [reports, setReports] = useState([])
  const isCustomer = useMemo(() => {
    return record?.get('access_type') === 'customer'
  }, [record?.get('access_type')])

  useEffect( () => {
    fetchReports(record.get('person')?.get('id')).then( reps => {
      setReports( reps )
    })
  }, [record.get('person')])

  return (
    <div>
      <Card>
        <CardContent>
          <GridContainer>
            <Col xs={12} sm={6}>
              <InputControl
                required
                name="name"
                label={attrName('name')}
                value={record.get('name')}
                type="text"
                inputComponent={Input}
                onChange={onChange}
              />
            </Col>
            <Col xs={12} sm={6}>
              <InputControl
                required
                name="email"
                label={attrName('email')}
                value={record.get('email')}
                type="text"
                inputComponent={Input}
                onChange={onChange}
              />
            </Col>
          </GridContainer>
          <GridContainer>
            <Col xs={12} sm={3}>
              <InputControl
                required
                value={record.get('access_type')}
                name={'access_type'}
                label={attrName('access_type')}
                inputComponent={InputSelect}
                onChange={onChange}
                InputProps={{
                  options: userAcessTypeEnum().array,
                  outValueType: 'string'
                }}
              />
            </Col>
            { isCustomer &&
              <Col xs={12} sm={3}>
                <InputControl
                  required
                  value={record.get('person')}
                  name={'person'}
                  stateName={'person'}
                  label={attrName('person')}
                  inputComponent={InputSelectAsync}
                  onChange={onChange}
                  InputProps={{
                    handleFetch: peopleFetch,
                    createEnabled: false,
                  }}
                />
              </Col>
            }
            <Col xs={12} sm={3}>
              <InputControl
                name="password"
                label={attrName('password')}
                value={record.get('password')}
                inputComponent={Input}
                autoComplete="off"
                InputProps={{
                  type: 'password'
                }}
                onChange={onChange}
              />
            </Col>
            <Col xs={12} sm={3}>
              <InputControl
                name="password_confirmation"
                label={attrName('password_confirmation')}
                value={record.get('password_confirmation')}
                inputComponent={Input}
                autoComplete="off"
                InputProps={{
                  type: 'password'
                }}
                onChange={onChange}
              />
            </Col>
            { isCustomer &&
              <Col xs={12}>
                <InputControl
                  required
                  value={record.get('reports')}
                  name={'reports'}
                  label={attrName('reports')}
                  inputComponent={InputSelect}
                  onChange={onChange}
                  InputProps={{
                    options: reports,
                    createEnabled: false,
                    isMulti: true,
                  }}
                />
              </Col>
            }
          </GridContainer>
        </CardContent>
      </Card>
    </div>
  );
}