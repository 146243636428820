import React, { memo } from 'react';
import PropTypes from 'prop-types';

import MuiButton from '@material-ui/core/Button';

import useStyles from './styles';

const colors = {
  primary: 'Primary',
  secondary: 'Secondary',
  success: 'Success',
  warning: 'Warning',
  error: 'Error',
  blue: 'Blue',
  purple: 'Purple',
  green: 'Green',
  orange: 'Orange',
  red: 'Red',
  black: 'Black',
  grey: 'Grey',
  rose: 'Rose',
  white: 'White',
};

function Button(props) {
  const classesDefault = useStyles();

  const { color, classes, ...ownProps} = props;

  return (
    <MuiButton
      color="default"
      {...ownProps}
      classes={{
        text: classesDefault[`text${colors[color]}`],
        outlined: classesDefault[`outlined${colors[color]}`],
        contained: classesDefault[`contained${colors[color]}`],
        ...classes,
      }}
    />
  );
}

Button.defaultProps = {
  ...MuiButton.defaultProps,
  color: 'primary',
  variant: 'contained',
};

Button.propTypes = {
  ...MuiButton.propTypes,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'warning',
    'error',
    'blue',
    'purple',
    'green',
    'orange',
    'red',
    'black',
    'grey',
    'rose',
    'inherit',
    'white',
  ]),
};

export default memo(Button)
