import SchemaBuilder from './SchemaBuilder';
import reportPbiTypeEnum from '~/enums/reportPbiTypeEnum';

const hidden = {hidden: true}

const columns = {
  id:       { type: 'integer',  title: 'ID' },
  name:     { type: 'string',   title: 'Nome', width: 250 },
  person:   {
    type: 'object',
    title: 'Empresa',
    isFilter: false,
    width: 300
  },
  pbi_type: {
    type: 'enum',
    title: 'Tipo',
    default: 'report',
    width: 150,
    _enum_: reportPbiTypeEnum
  },
  pbi_id:       { type: 'string',   title: 'PowerBI ID', width: 300 },
  pbi_group_id: { type: 'string',   title: 'PowerBI ID do Grupo', width: 300, ...hidden },
  public_url:   { type: 'string',   title: 'URL Pública', width: 300, tableView: false },
  created_at:    { type: 'datetime', default: '', title: 'Criado em', width: 150 },
  updated_at:    { type: 'datetime', default: '', title: 'Atualizado em', ...hidden },
  users: { type: 'array', title: 'Usuários', ...hidden, tableView: false, isFilter: false }
};

const reportSchema = new SchemaBuilder( columns );

export default reportSchema;