import React, { useState, useEffect, useContext, useCallback } from "react";
import {withRouter} from 'react-router';
// import Drawer from "@material-ui/core/Drawer";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Collapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import DrawerMenuContext from '../../contexts/DrawerMenuContext';
import ProfileDrawerContext from '~/contexts/ProfileDrawerContext';
import Permission from '../Permission';
import { usePermissions } from '../../hooks/usePermissions';

import formatUserAccessType from '~/utils/formatUserAccessType';
import { useMetadata } from '~/hooks/useMetadata';


import {Container, Logo, ProfileContainer, ProfileInfo} from './styles';

import ListItemLink from './ListItemLink';
import CustomRecordLink from './CustomRecordLink';

const ROOT_ROUTE = '/';
const drawerWidth = 265;
const drawerWidthResized = 80;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  appBarIconColor: {
    color: theme.palette.appBar.contrastText
  },
  appBarBgColor: {
    backgroundColor: theme.palette.appBar.main,
    color: theme.palette.appBar.contrastText
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: `calc(100% - ${drawerWidthResized}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  appBarShiftMobile: {
    marginLeft: 0,
    width: '100%'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    backgroundColor: theme.palette.menu.main,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    backgroundColor: theme.palette.menu.main,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: drawerWidthResized,
    [theme.breakpoints.up("sm")]: {
      width: drawerWidthResized
    }
  },
  toolbar: {
    display: "flex",
    backgroundColor: theme.palette.menu.main,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
}));

function DrawerMenu({items, location}) {
  const {open, setOpen, drawerMenuStorageState} = useContext(DrawerMenuContext);
  const [collapsedItems, setCollapsedItems] = useState(new Map());
  const { setProfileDrawerOpen } = useContext(ProfileDrawerContext)
  const { name, access_type } = useSelector(state => state.userProfile.data);
  const { can } = usePermissions();
  const classes = useStyles();
  const { metadata } = useMetadata();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    let collapsedItemName = currentCollapsedItem();

    if (collapsedItemName) {
      updateCollapsedItems(collapsedItemName);
    }
  }, []);


  function currentCollapsedItem() {
    let collapsedItemName;
    let routeIsCollapseItem = false;

    for (const item of items) {
      if (!item.collapse) continue;

      for (const colItem of item.collapse) {
        routeIsCollapseItem = isSelected(colItem.to);

        if (routeIsCollapseItem) {
          collapsedItemName = item.name;
          break;
        }
      }

      if (routeIsCollapseItem) break;
    }

    return collapsedItemName;
  }

  function updateCollapsedItems(itemName, forceUpdate = false) {
    if (open || forceUpdate) {
      const newColapsedItems = new Map();
      newColapsedItems.set(itemName, true);

      setCollapsedItems(newColapsedItems);
    }
  }

  function handleToogleDrawer() {
    setOpen(!open);
    closeColapsedItems(!open);
    localStorage.setItem('@App/state/drawerMenuOpen', !open);
  }

  function onMouseAction(openDrawer) {
    const drawerMenuOpen = localStorage.getItem('@App/state/drawerMenuOpen');

    // Open/Close Drawer on mouseOver/mouseLeave event
    if (drawerMenuOpen === 'false' && openDrawer !== open) {
      setOpen(openDrawer);
    }

    // Close all collased items opened
    if (drawerMenuOpen === 'false' && !openDrawer) {
      closeColapsedItems();
    }

    // Open collapsed item if route path includes on collapse items
    if (drawerMenuOpen === 'false' && openDrawer && openDrawer !== open) {
      let collapsedItemName = currentCollapsedItem();

      if (collapsedItemName) {
        updateCollapsedItems(collapsedItemName, true);
      }
    }
  }

  function closeColapsedItems(drawerOpen) {
    let collapsedItemName = currentCollapsedItem();
    const newColapsedItems = new Map();

    collapsedItems.forEach((value, key) => {
      if (drawerOpen && key === collapsedItemName) {
        newColapsedItems.set(key, true);
      } else {
        newColapsedItems.set(key, false);
      }
    });

    setCollapsedItems(newColapsedItems);
  }

  function handleColapseItem(itemName) {
    const newColapsedItems = new Map(collapsedItems);
    const currentColapseValue = !!collapsedItems.get(itemName)

    newColapsedItems.set(itemName, !currentColapseValue);

    setCollapsedItems(newColapsedItems);
  }

  function isSelected(itemRoute) {
    if (itemRoute === ROOT_ROUTE) {
      return location.pathname === itemRoute;
    }

    return location.pathname.startsWith(itemRoute);
  }

  function isSelectedColapse(collapsedItems) {
    let hasSelectedChild = false;

    for (const item of collapsedItems) {
      if (isSelected(item.to)) {
        hasSelectedChild = true;
        break;
      }
    }

    return hasSelectedChild;
  }

  const itemHasPermissionToShow = useCallback(
    (keyCode, collapseItems, onlyMaster) => {
      if (collapseItems) {
        return collapseHasPermissionedItemsToShow(collapseItems);
      }

      return can(keyCode, 'see', onlyMaster);
    },
    [can]
  );

  const collapseHasPermissionedItemsToShow = useCallback(
    (collapseItems) => {
      let hasPermission = false;

      for (const collapseItem of collapseItems || []) {
        if (can(collapseItem.keyCode, 'see', collapseItem.onlyMaster)) {
          hasPermission = true;
          break;
        }
      }

      return hasPermission;
    },
    [can]
  );

  return (
    <Container className="hidden-print" drawerOpen={drawerMenuStorageState}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]:       (open && !isMobile),
          [classes.appBarShiftMobile]: isMobile,
        })}
        classes={{
          colorPrimary: classes.appBarBgColor
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleToogleDrawer}
            edge="start"
            // classes={{colorPrimary: classes.appBarIconColor}}
          >
            <MenuIcon />
          </IconButton>
          <ProfileContainer>
            <a onClick={() => setProfileDrawerOpen(true)}>
              <ProfileInfo>
                <strong>{name}</strong>
                <p>{formatUserAccessType(access_type)}</p>
              </ProfileInfo>
              <ArrowDropDownIcon classes={{colorPrimary: classes.appBarIconColor}} />
            </a>
          </ProfileContainer>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        variant={ isMobile ? 'temporary' : 'permanent'}
        open={open}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
        onMouseOver={() => onMouseAction(true)}
        onMouseLeave={() => onMouseAction(false)}
        onClose={() => setOpen(false) }
        onOpen={() => setOpen(true) }
      >
        <div className={classes.toolbar}>
          <Logo drawerOpen={open} />
        </div>
        <Divider />
        <List>
          {items.map(item => (
            <ul key={item.name} style={{ padding: '0' }}>
              {itemHasPermissionToShow(item.keyCode, item.collapse, item.onlyMaster) && (
                <ListItemLink
                  selected={
                    !item.collapse
                      ? isSelected(item.to)
                      : isSelectedColapse(item.collapse)
                  }
                  name={item.name}
                  icon={item.icon}
                  to={item.to}
                  isCollapsible={!!item.collapse}
                  collapseIsOpen={collapsedItems.get(item.name)}
                  onClick={handleColapseItem}
                />
              )}

              {collapseHasPermissionedItemsToShow(item.collapse) && (
                <Collapse in={collapsedItems.get(item.name)} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.collapse.map(colItem => (
                      <Permission
                        key={colItem.name}
                        keyCode={colItem.keyCode}
                        onlyMaster={!!colItem.onlyMaster}
                        permission="see"
                      >
                        <ListItemLink
                          selected={isSelected(colItem.to)}
                          name={colItem.name}
                          icon={colItem.icon}
                          to={colItem.to}
                          isCollapsedItem={true}
                        />
                      </Permission>
                    ))}
                  </List>
                </Collapse>
              )}
            </ul>
          ))}
          { metadata.toArray().map( ([key, metad]) =>
            <Permission
              key={key}
              keyCode={`CustomRecord-${key}`}
              permission="see"
            >
              <CustomRecordLink
                isSelected={isSelected}
                metaData={metad}
              />
            </Permission>
          ) }
        </List>
      </SwipeableDrawer>
    </Container>
  );
}

DrawerMenu.defaultProps = {
  avatar: {},
  items: []
};

DrawerMenu.propTypes = {
  avatar: PropTypes.shape({
    name: PropTypes.string,
    roleName: PropTypes.string,
    url: PropTypes.string,
    onClick: PropTypes.func,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.object,
      name: PropTypes.string,
      to: PropTypes.string,
      collapse: PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.object,
          name: PropTypes.string,
          to: PropTypes.string,
        })
      )
    })
  ).isRequired,
};

export default withRouter(DrawerMenu);